<template>
  <div
    class="inflar"
    :style="
      `overflow: hidden; background: #e2e2e2; background: url('${$theme.background}')`
    "
  >
    <div
      class="inflar"
      style="opacity: 0.8;overflow: hidden; background: linear-gradient(to right, #000000, #111);"
    >
      <div class="inflar centraliza" style="overflow: hidden;opacity: 1.0;">
        <v-flex xs10 md3>
          <div class="inflar-porcentagem centraliza">
            <FormLogin />
          </div>
          <div class="inflar-porcentagem centraliza">
            <v-btn text dark @click="$router.push('/nosponsor')" class="fonte">
              Criar Uma Conta Grátis
            </v-btn>
          </div>

          <div class="inflar-porcentagem centraliza">
            <v-btn
              text
              dark
              @click="setModalForgotPassword(true)"
              class="fonte"
            >
              Esqueci a Senha
            </v-btn>
          </div>

          <ForgotPassword />
        </v-flex>
      </div>
      <v-dialog
        translate="slide-x-transition"
        v-model="captcha"
        persistent
        fullscreen
      >
        <div
          class="expande-horizontal centraliza column"
          style="height: 100vh; background: #555;"
        >
          <div
            class="centraliza"
            style="width: 300px; display: flex; flex-direction: column;"
          >
            <v-icon :color="$theme.primary" size="35">mdi-lock</v-icon>
            <h3
              class="fonte mt-1 text-center mb-3"
              :style="`color: ${$theme.primary}`"
            >
              Solucione o captcha!
            </h3>
            <v-text-field
              @keyup.enter="verificaCaptcha"
              v-model="resultado"
              type="number"
              rounded
              flat
              dark
              hide-details
              dense
              class="mt-2 mb-2"
              solo-inverted
              :color="$theme.primary"
              :prefix="`${random_dois} + ${random_um} = `"
              label="Resultado"
            ></v-text-field>
            <v-btn
              block
              rounded
              :color="$theme.primary"
              @click="verificaCaptcha"
            >
              Verificar
            </v-btn>
          </div>
        </div>
      </v-dialog>
    </div>
    <!-- <div style="position: absolute; bottom: 10px;" class="expande-horizontal centraliza column">
      <h5 class="fonte font-weight-light grey--text mb-1">Desenvolvido Por:</h5>
      <img height="40" src="https://firebasestorage.googleapis.com/v0/b/cosmos-home-site.appspot.com/o/8%201.png?alt=media&token=be454918-d24d-4129-8ade-b89de2ec10a5" alt="">
    </div> -->
  </div>
</template>

<script>
import FormLogin from "./components/FormLogin";
import ForgotPassword from "./components/ForgotPassword";
import FormSignup from "./components/FormSignup";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      random_um: 0,
      random_dois: 0,
      captcha: true,
      resultado: ""
    };
  },
  components: {
    FormLogin,
    ForgotPassword,
    FormSignup
  },
  methods: {
    ...mapActions(["setModalForgotPassword", "createGlobalMessage"]),
    verificaCaptcha() {
      const randoms = parseInt(this.random_dois) + parseInt(this.random_um);
      const res = parseInt(this.resultado);
      if (res === randoms) {
        this.captcha = false;
        this.createGlobalMessage({
          type: "success",
          message: "Bem Vindo ao Login!",
          timeout: 3000
        });
      }
    }
  },
  created() {
    const min = Math.ceil(1);
    const max = Math.floor(10);

    this.random_um = Math.floor(Math.random() * (max - min)) + min;
    this.random_dois = Math.floor(Math.random() * (max - min)) + min;
  }
};
</script>
